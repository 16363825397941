import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import amber from "@material-ui/core/colors/amber";

import Header from "../../components/Header";
import DialogInfo from "./sections/DialogInfo.js";
import InfosTree from "../../components/InfosTree/InfosTree";
import InfoToUpdate from "../../components/InfosTree/sections/InfoToUpdate";
import AlertsMap from "../../components/AlertsMap/AlertsMap";
import Search from "./sections/Search";
import AddNewInfo from "./sections/AddNewInfo.js";
import Alerts from "./sections/Alerts.js";
import Changelog from "../../components/Changelog/Changelog.js";
import GroupedCheck from "../../components/GroupedCheck";

import {
  patchInfo,
  getInfobyIdentifier,
  getInfoGrouped,
  postInfoParameters,
  postInfoParameterAreas,
  deleteInfoParameters,
  postInfoResources,
  getReview,
  patchReview
} from "../../api/api.js";

import { updateGroupedInfo, updateHistory, utcStringDate } from "../../lib/utils";

const LBL_WAIT = "WAIT... RETRIEVING DATA";
const PAGE_TITLE = "LIST VIEW";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  close: {
    padding: theme.spacing(1) / 2
  }
});

const SNACK_MSG_UP = "Updated!";
const SNACK_MSG_NOTVALID = "Operation not valid!";
const SNACK_MSG_RELOAD = "Done!";
const DEFAULT_GROUPED = "?status=UN";

class LandingPage extends Component {
  state = {
    alerts: [],
    info: {},
    mapData: {},
    dialogInfo: false,
    identifier: "",
    editInfo: false,
    openSnack: false,
    snackMessage: SNACK_MSG_UP,
    valueTab: 0,
    groupedParams: DEFAULT_GROUPED,
    isLoadingGrouped: false,
    infosUpdated: [],
    infosReviewed: []
  };

  UNSAFE_componentWillMount() {
    // info unmodified 20 days before
    let lastModified = new Date()
    lastModified.setDate(lastModified.getDate() - 20)
    const q = "?status=UN&modified__gte=" + lastModified.toISOString().substring(0,19); 
    this.setGroupParams(q);
  }

  handleDialogInfoClose = _ => {
    this.setState({ dialogInfo: false, info: {} });
  };

  handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ openSnack: false });
  };

  render() {
    const { classes, user, actions, allData } = this.props;
    const {
      info,
      dialogInfo,
      identifier,
      editInfo,
      mapData,
      valueTab,
      groupedParams,
      isLoadingGrouped
    } = this.state;
    actions.menuTab = this.handleMenuTab;
    actions.viewInfo = this.viewInfo;
    actions.editInfo = this.editInfo;
    actions.approveInfo = this.approveInfo;
    actions.deleteInfo = this.dischargeInfo;
    actions.unassignInfo = this.unassignInfo;
    actions.gdacs = this.gdacsInfo;
    actions.hazardsInfo = this.hazardsInfo;
    actions.hystoryInfo = this.hystoryInfo;
    actions.addInfo = this.addInfo;
    actions.getNumActivity = this.getNumberActivity;
    actions.updateSearchAlert = this.updateSearchAlert;
    actions.reloadInfo = this.reloadInfo;
    actions.reloadUnassigned = this.reloadUnassigned;
    actions.getInfoTot = this.getInfoTot;
    actions.setGroupParams = this.setGroupParams;
    actions.addParamtoInfo = this.addParamtoInfo;
    actions.removeParamtoInfo = this.removeParamtoInfo;
    actions.updateInfoToValidate = this.updateInfoToValidate;
    actions.clearReviewd = this.clearReviewd;
    return (
      <div>
        <Header user={user} actions={actions} />
        <Card>
          <CardContent style={{ padding: 0 }}>
            {valueTab === 0 && (
              <React.Fragment>
                <Grid container>
                  <Grid item xs={12}>
                    <GroupedCheck
                      actions={actions}
                      groupedParams={groupedParams}
                      pageTitle={PAGE_TITLE}
                      alertsTree={mapData}
                    />
                  </Grid>
                </Grid>
                {this.state.infosUpdated.length > 0 && (
                  <Grid item xs={12}>
                    <InfoToUpdate
                      infoToValidate={this.state.infosUpdated}
                      user={this.props.user}
                      actions={actions}
                      allData={allData}
                      reviewed={this.state.infosReviewed}
                    />
                  </Grid>
                )}
                {isLoadingGrouped ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Divider style={{ marginTop: 8 }} />
                      <div style={{ padding: 10 }}>
                        <Typography variant="h6">{LBL_WAIT}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                    <InfosTree
                      alertsTree={mapData}
                      actions={actions}
                      allData={allData}
                      infoToReview={this.state.infosUpdated}
                    />
                  )}
              </React.Fragment>
            )}
            {valueTab === 1 && (
              <AlertsMap
                alertsTree={mapData}
                actions={actions}
                groupedParams={groupedParams}
                isLoadingGrouped={isLoadingGrouped}
              />
            )}
            {valueTab === 2 && (
              <Search user={user} actions={actions} allData={allData} />
            )}
            {valueTab === 3 && (
              <AddNewInfo user={user} actions={actions} allData={allData} />
            )}
            {valueTab === 4 && (
              <Alerts user={user} actions={actions} allData={allData} />
            )}
            {valueTab === 5 && <Changelog />}
          </CardContent>
        </Card>
        {info.hasOwnProperty("description") && (
          <DialogInfo
            open={dialogInfo}
            close={this.handleDialogInfoClose}
            info={info}
            identifier={identifier}
            actions={actions}
            edit={editInfo}
            allData={allData}
            user={user}
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleCloseSnack}
        >
          <SnackbarContent
            style={{ backgroundColor: amber[500] }}
            aria-describedby="client-snackbar"
            message={<span id="message-id">{this.state.snackMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleCloseSnack}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
      </div>
    );
  }

  handleMenuTab = valueTab => this.setState({ valueTab });

  viewInfo = (info, identifier) => {
    // console.log(info, identifier);
    this.setState({ dialogInfo: true, editInfo: false, info, identifier });
  };

  editInfo = (info, identifier) => {
    // console.log(info, identifier);
    this.setState({ dialogInfo: true, editInfo: true, info, identifier });
  };

  approveInfo = async (info, alertIdentifier) => {
    const status = "AP";
    const response = await patchInfo(this.props.user, info.identifier, {
      status: status
    });
    if (response.hasOwnProperty("status")) {
      this.updateInfoGrouped(response);
    } else {
      this.setState({
        openSnack: true,
        snackMessage: SNACK_MSG_NOTVALID
      });
    }
  };

  dischargeInfo = async (info, alertIdentifier) => {
    const status = "DI";
    const response = await patchInfo(this.props.user, info.identifier, {
      status: status
    });
    if (response.hasOwnProperty("status")) {
      this.updateInfoGrouped(response);
    } else {
      this.setState({
        openSnack: true,
        snackMessage: SNACK_MSG_NOTVALID
      });
    }
  };

  unassignInfo = async (info, alertIdentifier) => {
    const status = "UN";
    const response = await patchInfo(this.props.user, info.identifier, {
      status: status
    });
    if (response.hasOwnProperty("status")) {
      this.updateInfoGrouped(response);
    } else {
      this.setState({
        openSnack: true,
        snackMessage: SNACK_MSG_NOTVALID
      });
    }
  };

  gdacsInfo = async (info, alertIdentifier, id) => {
    const response = await patchInfo(this.props.user, info.identifier, {
      gdacs_severity_id: id
    });
    if (response.hasOwnProperty("gdacs_severity")) {
      this.updateInfoGrouped(response);
      this.setState({infosReviewed:[info.identifier]})
    } else {
      this.setState({
        openSnack: true,
        snackMessage: SNACK_MSG_NOTVALID
      });
    }
  };

  hazardsInfo = async (info, ids) => {    
    const response = await patchInfo(this.props.user, info.identifier, {
      hazard_classifications_id: ids
    });
    if (response.hasOwnProperty("identifier")) {
      this.updateInfoGrouped(response, false);
    }
  }

  hystoryInfo = async (info, history) => {    
    const response = await patchInfo(this.props.user, info.identifier, {
      history: history
    });
    console.log(response)
  }
  
  reloadInfo = async infoIdentifier => {
    const info = await getInfobyIdentifier(this.props.user, infoIdentifier);
    this.setState({ info, editInfo: true, infosReviewed:[info.identifier] });
    this.updateInfoGrouped(info, false);
  };

  updateInfoGrouped = (info, isSnack = true) => {
    const currentMapData = Object.assign({}, this.state.mapData);
    const mapData = updateGroupedInfo(info, currentMapData);
    this.setState({ mapData, openSnack: isSnack, snackMessage: SNACK_MSG_UP });
  };

  reloadInfoGrouped = async (isSnack = true) => {
    const mapData = await getInfoGrouped(this.props.user);
    this.setState({ mapData, openSnack: isSnack, snackMessage: SNACK_MSG_UP });
  };

  addInfo = async alertIdentifier => {
    this.reloadUnassigned();
  };

  getInfoTot = data => {
    let tot = 0;
    try {
      Object.keys(data).forEach(c => {
        Object.keys(data[c]).forEach(cc => {
          Object.keys(data[c][cc]).forEach(ii => {
            tot += 1;
          });
        });
      });
    } catch (err) {
      // console.log("get tot.... ", err);
    }
    return tot;
  };

  getNumberActivity = (field, item) => {
    if (this.state.mapData.hasOwnProperty("detail")) return "";
    try {
      let alerts = [];
      switch (field) {
        case "continent":
          const c = this.state.mapData[item];
          const cc = Object.values(c);
          cc.forEach(r => {
            r.forEach(i => alerts.push(i));
          });
          break;
        case "country":
          Object.keys(this.state.mapData).forEach(c => {
            const cc = this.state.mapData[c];
            Object.keys(cc).forEach(ck => {
              if (ck === item) {
                const country = this.state.mapData[c][ck];
                country.forEach(i => alerts.push(i));
              }
            });
          });
          break;
        default:
          console.log("not found");
      }
      const tot = this._getNumbers(alerts);
      return `[${tot["un"]}/${tot["ap"]}/${tot["di"]}]`;
    } catch (err) {
      console.log(err);
    }
    return "";
  };

  updateSearchAlert = info => {
    this.updateInfoGrouped(info);
  };

  setGroupParams = async q => {
    this.setState({ groupedParams: q, isLoadingGrouped: true });
    const mapData = await getInfoGrouped(this.props.user, q);
    this.setState({
      mapData,
      openSnack: true,
      snackMessage: SNACK_MSG_RELOAD,
      isLoadingGrouped: false
    });
    this.apiInfoUpdated();
  };

  reloadUnassigned = async _ => {
    const mapData = await getInfoGrouped(
      this.props.user,
      this.state.groupedParams
    );
    this.setState({
      mapData,
      openSnack: true,
      snackMessage: SNACK_MSG_RELOAD
    });
  };

  addParamtoInfo = async (info, param) => {
    if (!param.hasOwnProperty("areas")) param["areas"] = [];
    const areas = [...param["areas"]];
    delete param["areas"];
    delete param["elaborated"];
    if (param.hasOwnProperty("onset")) param["onset"] = utcStringDate(param["onset"])
    if (param.hasOwnProperty("effective")) param["effective"] = utcStringDate(param["effective"])
    if (param.hasOwnProperty("expires")) param["expires"] = utcStringDate(param["expires"])    
    const response = await postInfoParameters(
      this.props.user,
      info.identifier,
      param
    );
    if (response.hasOwnProperty("id")) {
      const promises = areas.map(async area =>
        postInfoParameterAreas(
          this.props.user,
          info.identifier,
          response.id,
          area
        )
      );
      await Promise.all(promises);
    }
  };

  clearReviewd = () => {
    this.setState({infosReviewed:[] })
  }

  removeParamtoInfo = async (info, param) => {
    const resp = await deleteInfoParameters(
      this.props.user,
      info.identifier,
      param.id
    );
    // console.log(resp)
  };

  updateInfoToValidate = async (info, updated, type) => {
    const params = updated.values;

    if (type === "update") {
      if (params.hasOwnProperty("parameters")) {
        const promises_params = params["parameters"].map(async p =>
          this.addParamtoInfo(info, p)
        );
        await Promise.all(promises_params);
      }
      if (params.hasOwnProperty("resources")) {
        const promises = params["resources"].map(async resource =>
          postInfoResources(this.props.user, info.identifier, resource)
        );
        await Promise.all(promises);
      }

      if (params.hasOwnProperty("info")) {
        if (params.info.hasOwnProperty("content")) {
          params.info["description"] = params.info["content"];
          delete params.info.content
          params.info["history"] = updateHistory(info.history, {"description": info["description"]})
        }
        const resp = await patchInfo(
          this.props.user,
          info.identifier,
          params.info
        );
        // console.log(resp)
        this.setState({infosReviewed:[info.identifier]})
      }
    }
    const data = { is_reviewed: true };
    patchReview(this.props.user, updated.id, info.identifier, data);
    this.reloadInfo(info.identifier);
    // this.apiInfoUpdated();
    this.setGroupParams(this.state.groupedParams);
  };

  apiInfoUpdated = async _ => {
    const response = await getReview(this.props.user);
    if (Array.isArray(response)) this.setState({ infosUpdated: response });
    /*
    if (response.hasOwnProperty("results"))
      this.setState({ infosUpdated: response.results });
    */
  };

  _getNumbers = infos => {
    let unTot = 0;
    let apTot = 0;
    let diTot = 0;

    let un = infos.filter(i => i.status === "UN");
    let ap = infos.filter(i => i.status === "AP");
    let di = infos.filter(i => i.status === "DI");
    unTot += un.length;
    apTot += ap.length;
    diTot += di.length;
    return { un: unTot, ap: apTot, di: diTot };
  };
}

LandingPage.propTypes = {
  user: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default withStyles(styles)(LandingPage);
