import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import blue from "@material-ui/core/colors/blue";
import deepOrange from "@material-ui/core/colors/deepOrange";
import moment from "moment";
import InfoTable from "./InfoTable.js";

import { STATUS_DEF } from "../../lib/utils.js";

const MOMENT_FORMAT = "MMMM Do YYYY";

const styles = {
  top: {
    marginTop: 20
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  rootInfoTable: {
    flexGrow: 1
  }
};

class ListInfoTable extends Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes, item, identifier, actions, allData } = this.props;
    const lblRegions = item.hasOwnProperty("regions")
      ? item.regions
          .map(r => {
            return `${r.name} ${r.country.name}`;
          })
          .join()
      : "";
    const lblCountries = item.hasOwnProperty("countries")
      ? item.countries.map(c => c.name).join()
      : "";
    return (
      <React.Fragment>
        <ListItem button>
          <ListItemText
            onClick={this.handleClick}
            primary={`${item.event}`}
            secondary={
              <React.Fragment>
                <Typography component="span" style={{marginRight:5}}>
                  country/regions: {lblRegions} {lblCountries}
                </Typography>
                <Typography component="span" style={{marginRight:5}}>
                  onset/expires {moment(item.onset).format(MOMENT_FORMAT)} -{" "}
                  {moment(item.expires).format(MOMENT_FORMAT)}
                </Typography>
                <Typography component="span">
                  status: {STATUS_DEF[item.status]}
                </Typography>
              </React.Fragment>
            }
          />
          <IconButton
            onClick={() => actions.viewInfo(item, identifier)}
            aria-label="View"
          >
            <InfoIcon style={{ color: blue[500] }} />
          </IconButton>
          {this.state.open ? (
            <ExpandLess onClick={this.handleClick} />
          ) : (
            <ExpandMore onClick={this.handleClick} />
          )}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <Grid container className={classes.rootInfoTable}>
            <InfoTable
              info={item}
              identifier={identifier}
              actions={actions}
              allData={allData}
            />
          </Grid>
        </Collapse>
      </React.Fragment>
    );
  }
}

class InfoSearch extends Component {
  render() {
    const jsonInfos = { info: this.props.jsonInfos };
    const { classes, identifier, actions, allData } = this.props;
    return (
      <div className={classes.top}>
        <List>
          {jsonInfos.info.map((item, idx) => (
            <ListInfoTable
              key={`ii-${idx}`}
              item={item}
              identifier={identifier}
              actions={actions}
              allData={allData}
              classes={classes}
            />
          ))}
        </List>
      </div>
    );
  }
}

InfoSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  jsonInfos: PropTypes.array.isRequired,
  identifier: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default withStyles(styles)(InfoSearch);
