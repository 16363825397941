export const CHANGELOGS = [
  { version: "2.1.9", text: "Updated Review section when editing info" },
  { version: "2.1.8", text: "Added identifier on info Search" },
  { version: "2.1.7", text: "Updated Review section" },
  { version: "2.1.6", text: "Added column Area in Parameters Review table" },
  { version: "2.1.5", text: "Added column Area in Parameters table" },
  {
    version: "2.1.4",
    text: "Updated button style Info countries and regions update",
  },
  {
    version: "2.1.3",
    text: "Added precise location (areas) of parameter event",
  },
  { version: "2.1.2", text: "Added Info countries and regions update" },
  { version: "2.1.1", text: "Added DSS ALERT view" },
  { version: "2.1.0", text: "Added DSS region alert" },
  { version: "2.0.0", text: "Enabled Docker management" },
  {
    version: "1.0.5",
    text: "Updated required fields (expires and effective date) on info parameters",
  },
  { version: "1.0.4", text: "Enabled Edit geometries on info map" },
  { version: "1.0.3", text: "Removed Save button when editing info" },
];
