import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// import { DatePicker } from "material-ui-pickers";
import { DatePicker } from "@material-ui/pickers";

import { IconButton, InputAdornment } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";

import moment from "moment";

// import AlertsSearchTree from "../../../components/AlertsSearch/AlertsSearchTree";
// import Infos from "../../../components/AlertsSearch/sections/Infos";
import InfoSearch from "../../../components/Infos/InfoSearch";
import DialogInfo from "./DialogInfo.js";

import {
  searchInfos,
  searchCountries,
  patchInfo,
  getInfobyIdentifier,
  deleteInfoParameters
} from "../../../api/api.js";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: 20,
    marginRight: 20,
    width: 550
  },
  dateField: {
    marginBottom: 30,
    marginTop: 30,
    marginLeft: 20,
    marginRight: 20
  },
  selectField: {
    marginTop: 30,
    marginLeft: 20,
    marginRight: 20
  }
});

class Search extends Component {
  state = {
    response: { results: [] },
    loading: false,
    mapData: {},
    count: -1,
    onset: "",
    expires: "",
    country: "",
    countryHelper: "starts with",
    event: "",
    infoStatus: "",
    info: {},
    dialogInfo: false,
    identifier: "",
    editInfo: false
  };

  UNSAFE_componentWillMount() {
    // const current = new Date().toJSON().substr(0, 10);
    this.setState({
      onset: moment().subtract(2, "week"),
      expires: moment().add(1, "day")
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });

    if (name === "country") {
      if (event.target.value.length > 2) {
        this._getCountries(event.target.value);
      } else {
        this.setState({ countryHelper: "starts with" });
      }
    }
  };

  handleDateChangeFrom = date => {
    const d = date || "";
    this.setState({ onset: d });
  };

  handleDateChangeTo = date => {
    const d = date || "";
    this.setState({ expires: d });
  };

  handleDialogInfoClose = _ => this.setState({ dialogInfo: false });

  render() {
    const { classes, allData, user } = this.props;
    const {
      response,
      loading,
      count,
      info,
      identifier,
      editInfo,
      dialogInfo,
      countryHelper
    } = this.state;
    const actionsSearch = {
      viewInfo: this.viewInfo
    };
    actionsSearch.editInfo = this.editInfo;
    actionsSearch.approveInfo = this.approveInfo;
    actionsSearch.deleteInfo = this.dischargeInfo;
    actionsSearch.unassignInfo = this.unassignInfo;
    actionsSearch.updateInfo = this.updateInfo;
    actionsSearch.reloadInfo = this.reloadInfo;
    actionsSearch.gdacs = this.gdacsInfo;
    actionsSearch.hazardsInfo = this.hazardsInfo;
    actionsSearch.removeParamtoInfo = this.removeParamtoInfo;
    actionsSearch.hystoryInfo = this.hystoryInfo;

    return (
      <Grid container style={{ marginTop: 20 }}>
        <Grid item xs={12} style={{ padding: 10 }}>
          {info.hasOwnProperty("description") && (
            <DialogInfo
              open={dialogInfo}
              close={this.handleDialogInfoClose}
              info={info}
              identifier={identifier}
              actions={actionsSearch}
              edit={editInfo}
              allData={allData}
              user={user}
            />
          )}
          <Typography variant="h5" gutterBottom>
            Search
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root} elevation={1}>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={12} className={classes.container}>
                <DatePicker
                  autoOk
                  clearable
                  className={classes.dateField}
                  variant="outlined"
                  style={{ width: 175 }}
                  label="from"
                  helperText="onset time"
                  value={this.state.onset}
                  onChange={this.handleDateChangeFrom}
                  animateYearScrolling={false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <DateRangeIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <DatePicker
                  autoOk
                  clearable
                  className={classes.dateField}
                  variant="outlined"
                  style={{ width: 175 }}
                  label="to"
                  helperText="expires time"
                  value={this.state.expires}
                  onChange={this.handleDateChangeTo}
                  animateYearScrolling={false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <DateRangeIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  id="country"
                  label="Country"
                  className={classes.dateField}
                  value={this.state.country}
                  helperText={countryHelper}
                  onChange={this.handleChange("country")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  id="event"
                  label="Event"
                  className={classes.dateField}
                  value={this.state.event}
                  helperText="contains"
                  onChange={this.handleChange("event")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  id="identifier"
                  label="Identifier"
                  className={classes.dateField}
                  value={this.state.identifier}
                  helperText="contains"
                  onChange={this.handleChange("identifier")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <FormControl variant="outlined" className={classes.selectField}>
                  <InputLabel shrink htmlFor="status-label">
                    Status
                  </InputLabel>
                  <Select
                    value={this.state.infoStatus}
                    onChange={this.handleChange("infoStatus")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="UN">Unassigned</MenuItem>
                    <MenuItem value="AP">Approved</MenuItem>
                    <MenuItem value="DI">Deleted</MenuItem>
                  </Select>
                  <FormHelperText>info status</FormHelperText>
                </FormControl>
                <Button
                  onClick={this.handleSearch}
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: 10 }}
                /* disabled={this.state.expires.includes("T") ? false : true} */
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            {loading && <div>wait loading data....</div>}
            {count === 0 && <div>no data found</div>}
            {count > 0 && <div>Found: {count} items</div>}
            {response.results.length > 0 && (
              <InfoSearch
                jsonInfos={response.results}
                identifier="info"
                actions={actionsSearch}
                allData={allData}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }

  hystoryInfo = async (info, history) => {    
    const response = await patchInfo(this.props.user, info.identifier, {
      history: history
    });
    console.log(response)
  }

  removeParamtoInfo = async (info, param) => {
    const resp = await deleteInfoParameters(
      this.props.user,
      info.identifier,
      param.id
    );
    console.log(resp);
  };

  hazardsInfo = async (info, ids) => {
    const response = await patchInfo(this.props.user, info.identifier, {
      hazard_classifications_id: ids
    });
    if (response.hasOwnProperty("identifier")) {

      this.updateInfo(response, false);
    }
  }

  handleSearch = async _ => {
    // if (this.state.onset.includes("T") && this.state.expires.includes("T")) {
    this.setState({ loading: true, count: -1, response: { results: [] } });
    let queryString = `?onset__gte=${this.state.onset
      .toISOString()
      .substr(0, 19)}&expires__lte=${this.state.expires
        .toISOString()
        .substr(0, 19)}`; // "?infos__modified__gte=2019-01-17T12:00:00";
    if (this.state.country.length > 2)
      queryString += `&countries__name__istartswith=${this.state.country}`;
    if (this.state.infoStatus.length > 1)
      queryString += `&status=${this.state.infoStatus}`;
    if (this.state.event.length > 2)
      queryString += `&event__icontains=${this.state.event}`;
    if (this.state.identifier.length > 2)
      queryString += `&identifier=${this.state.identifier}`;
    

    const response = await searchInfos(this.props.user, queryString);
    // console.log(response);
    if (response.hasOwnProperty("results")) {
      this.setState({
        response,
        loading: false,
        count: response.count
      });
    } else {
      this.setState({
        response: { results: [] },
        loading: false,
        count: 0
      });
    }
    // }
  };

  viewInfo = (info, identifier) => {
    this.setState({ dialogInfo: true, editInfo: false, info, identifier });
  };

  editInfo = (info, identifier) =>
    this.setState({ dialogInfo: true, editInfo: true, info, identifier });

  approveInfo = async (info, alertIdentifier) => {
    const status = "AP";
    const response = await patchInfo(this.props.user, info.identifier, {
      status: status
    });
    if (response.hasOwnProperty("status")) {
      info.status = response.status;
      this._updateSearchResults(alertIdentifier, info);
    }
  };

  dischargeInfo = async (info, alertIdentifier) => {
    const status = "DI";
    const response = await patchInfo(this.props.user, info.identifier, {
      status: status
    });
    if (response.hasOwnProperty("status")) {
      info.status = response.status;
      this._updateSearchResults(alertIdentifier, info);
    }
  };

  unassignInfo = async (info, alertIdentifier) => {
    const status = "UN";
    const response = await patchInfo(this.props.user, info.identifier, {
      status: status
    });
    if (response.hasOwnProperty("status")) {
      info.status = response.status;
      this._updateSearchResults(alertIdentifier, info);
    }
  };

  gdacsInfo = async (info, alertIdentifier, id) => {
    const response = await patchInfo(this.props.user, info.identifier, {
      gdacs_severity_id: id
    });
    if (response.hasOwnProperty("gdacs_severity")) {
      info.gdacs_severity = response.gdacs_severity;
      this._updateSearchResults(alertIdentifier, info);
    }
  };

  reloadInfo = async infoIdentifier => {
    const info = await getInfobyIdentifier(this.props.user, infoIdentifier);
    this.setState({ info });
    this._updateSearchResults(info.alert_id, info);
  };

  updateInfo = async (identifier, infoEdit) => {
    // const { critical } = infoEdit;
    delete infoEdit["critical"];
    if (Object.keys(infoEdit).length < 1) return;
    let info = {};
    const response = await patchInfo(this.props.user, identifier, infoEdit);
    if (response.hasOwnProperty("alert_id")) {
      info = { ...this.state.info, ...infoEdit, errors: null };
      this._updateSearchResults(info.alert_id, info);
    } else {
      info = { ...this.state.info, errors: response };
    }
    this.setState({ info });
  };

  _updateSearchResults = (alertIdentifier, info) => {
    const response = Object.assign({}, this.state.response);
    const newResults = [];
    response.results.forEach(i => {
      if (i.identifier === info.identifier) {
        newResults.push(info);
      } else {
        newResults.push(i);
      }
    });
    this.setState({
      response: { ...this.state.response, results: newResults }
    });
    this.props.actions.updateSearchAlert(info);
  };

  _getCountries = async item => {
    const response = await searchCountries(
      this.props.user,
      `?name__istartswith=${item}`
    );
    if (response.hasOwnProperty("results")) {
      if (response.results.length > 0) {
        this.setState({
          countryHelper:
            "found: " + [...new Set(response.results.map(c => c.name))].join()
        });
      } else {
        this.setState({ countryHelper: "country not valid!" });
      }
    } else {
      this.setState({ countryHelper: "country not valid!" });
    }
  };
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default withStyles(styles)(Search);
